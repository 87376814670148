import React, { lazy, Suspense } from 'react'
import SectionHeading from './SectionHeading'
import DescriptionText from './DescriptionText'
import MiniCard from './MiniCard'

import { WhyNervaData } from '../data'
const StarsCanvas = lazy(() => import('./StarCanvas'))
const WhyNerva = () => {
  return (
    <div className="why-nerva">
      <div className="why-nerva-title">
        <SectionHeading text={WhyNervaData.heading} />
      </div>
      <div className="why-nerva-description">
        <DescriptionText
          text={WhyNervaData.description}
          style={{ textAlign: 'center' }}
        />
      </div>

      <div className="why-nerva-box">
        <div className="why-nerva-blue-background"></div>
        <MiniCard data={WhyNervaData.miniCardDetails[0]} />
        <MiniCard data={WhyNervaData.miniCardDetails[1]} />
        <MiniCard data={WhyNervaData.miniCardDetails[2]} />
      </div>
      <Suspense fallback={<></>}>
        <StarsCanvas />
      </Suspense>
    </div>
  )
}

export default WhyNerva
