import React, { Suspense, lazy } from 'react'
import SectionHeading from './SectionHeading'
import {
  OurSolutionDataBlue,
  OurSolutionDataWhite,
} from './OurSolutionDataCard'

const StarsCanvas = lazy(() => import('./StarCanvas'))

const OurSolutions = (props) => {
  const { solutions } = props

  return (
    <div className="our-solutions" id="our-solutions">
      <SectionHeading text={solutions.heading} />
      <div className="our-solutions-data">
        {solutions.benefits.map((data, index) => {
          if (index % 2) {
            return (
              <OurSolutionDataWhite
                key={index}
                title={data.title}
                description={data.description}
                imgSrc={data.imgSrc}
              />
            )
          } else {
            return (
              <OurSolutionDataBlue
                key={index}
                title={data.title}
                description={data.description}
                imgSrc={data.imgSrc}
              />
            )
          }
        })}
      </div>
      <Suspense fallback={<></>}>
        <StarsCanvas size={0.002} count={5000} />
      </Suspense>
    </div>
  )
}

export default OurSolutions
